<template>
    <div class="box">
        <div class="info">
            <div class="hand">
                <img src="../../assets/images/staffInvite/hand.png" alt="">
            </div>
            <div class="tip">
                <div class="sanjiao"></div>
                <div class="tip_text">给个手带你去领券</div>
                
            </div>
            <div class="mobile">
                <img src="../../assets/images/staffInvite/mobile.png" alt="">
                <input type="tel" placeholder="输入手机号码" v-model="phone" maxlength="11">
            </div>
            <div class="code">
                <div class="codeInp">
                    <img src="../../assets/images/staffInvite/code.png" alt="" >
                    <input type="tel" placeholder="输入验证码" maxlength="6" v-model="code">
                </div>
                <button @click="getCode" v-if="!counting">获取验证码</button>
                <!-- <div>60秒</div> -->
                <button v-else>{{count == 60?"重新发送" : `${count}秒`}}</button>
            </div>
            <div class="downLoad" @click="toDownLoad">
                立即下载 - 领券
            </div>
        </div>
        <div class="text">
            *格行内部邀请活动链接，切勿外传
        </div>
        <div class="rule">
            <!-- <div class="ruleTitle">如何领取</div> -->
            <img src="../../assets/images/staffInvite/rule.png" alt="">
        </div>
        <div class="prompt">
            <div class="prompt_title">
                <img src="../../assets/images/staffInvite/prompt.png" alt="">
            </div>
            <div class="prompt_main">
                <p>1.活动对象：限邀请新用户参与</p>
                <p>2.填写手机号 - 下载APP - 登录 - 领取2张1500G     流量月包券</p>
                <p>3.本活动为公司内部活动，切勿外传</p>
                <p>4.如有咨询或建议可通过格行上网APP及微信公众号联系我们</p>
            </div>
        </div>
        <div class="specs">
            ©2023 格行上网
        </div>
    </div>
</template>

<script>
var url = window.location.href; //获取当前url
if (url.indexOf('?') > -1){
    var cs = url.split("?")[1]; //获取?之后的参数字符串
    var cs_arr = cs.split("&"); //参数字符串分割为数组
    var cs={};
    for(var i =0; i<cs_arr.length;i++){
        cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
    }
}else{
    // console.log('没有数据');
    
}
import {getInviteCode,addInvite} from '@/untils/api.js'
export default {
    data(){
        return{
            phone:'',
            code:'',
            invite_code:'',//邀请码，传参
            countdown: null,
            targetTime: null,
            count: 60, // 倒计时秒数
            counting: false, // 是否正在倒计时
            hostUrl:window.location.host,   // 获取本地域名地址
        }
    },
    mounted(){
        //倒计时
		this.startCountdown();
        console.log(this.hostUrl);
    },
    methods:{
        // 获取验证码
        getCode(){
            if(this.phone =='' || this.phone.length != 11){
                this.$toast('您输入的手机号有误')
                return false
            }
            if(!this.counting){
                let data = {
                    phone:this.phone
                }
                getInviteCode({data}).then(res=>{
                    if(res.data.code == 1){
                        this.$toast(res.data.msg)
                        this.counting = true;
                        let timer = setInterval(() => {
                            this.count--;
                            if (this.count <= 0) {
                            clearInterval(timer);
                            this.counting = false;
                            this.count = 60;
                            }
                        }, 1000);
                    }else{
                        this.$toast(res.data.msg)
                    }
                })
            }
            
        },
        // 立即下载、领券
        toDownLoad(){
            if(this.phone =='' || this.phone.length != 11){
                return this.$toast('您输入的手机号有误')
            }else if(this.code =='' || this.code.length != 6 ){
                return this.$toast('您输入的验证码有误')
            }else{
                let data = {
                    phone:this.phone,
                    code:this.code,
                    invite_code:cs.invite_code
                }
                addInvite({data}).then(res =>{
                    if(res.data.code == 1){
                        this.$toast(res.data.msg)
                        if((this.hostUrl == "iot.yunruiot.com" || this.hostUrl == "iots.yunruiot.com")){
                            window.location.href="https://a.app.qq.com/o/simple.jsp?pkgname=com.yunrui.gexingshangwang"
                            return false
                        }
                    }else{
                        this.$toast(res.data.msg)
                    }
                })
            }
        },
        // 倒计时
        startCountdown() {
            const now = new Date();
            const target = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 24, 0, 0);
            this.targetTime = target - now;
            this.countdown = setInterval(() => {
            this.targetTime -= 1000;
            if (this.targetTime <= 0) {
                clearInterval(this.countdown);
            }
            }, 1000);
        },
    },
}
</script>

<style lang="less" scoped>
    .box{
        background-image: url('../../assets/images/staffInvite/wifi.png');
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: 100vh;
        overflow: hidden;
        .info{
            background: linear-gradient(90deg, #FFFFFF 0%, #FFFBED 100%);
            border-radius: 12px;
            margin: 211px 38px 0;
            position: relative;
            padding: 10px;
            .hand{
                position: absolute;
                top: -36px;
                right: -20px;
                img{
                    width: 143px;
                }
            }
            .tip{
                width: 124px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: linear-gradient(90deg, #E03D3D 0%, #F64279 100%);
                border-radius: 2px 8px 8px 4px;
                margin: 3px 0px 23px -17px;
                font-weight: 500;
                color: #FFFFFF;
                font-size: 14px;
                position: relative;
                z-index: 2;
                .tip_text{
                    border-radius: 2px 8px 8px 4px;
                    background: linear-gradient(90deg, #E03D3D 0%, #F64279 100%);
                }
                .sanjiao{
                    width:0px;
                    height:0px;
                    border:7px solid transparent;/*以下四个样式对应四种三角形，任选其一即可实现*/
                    //  border-top-color:lightseagreen;
                    // border-left-color:lightseagreen; 
                    border-right-color:#A1113A;
                    // border-bottom-color:lightseagreen;
                    // margin-top: -7px;
                    // margin-left: -6px;
                    position: absolute;
                    bottom: -6px;
                    left: -6px;
                    z-index: -1;
                }
            }
            ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color: #C4D0E0;
            }
            :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: #C4D0E0;
            }
            ::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: #C4D0E0;
            }
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #C4D0E0;
            }

            .mobile{
                border: 3px solid #40A0FF;
                box-shadow: 0px 4px 12px 0px rgba(115,173,234,0.15);
                height: 54px;
                margin: 23px 20px 15px;
                background: #FFFFFF;
                border-radius: 12px;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                img{
                    width: 13px;
                    height: 18px;
                    margin-left: 12px;
                    margin-right: 11px;
                }
                input{
                    width: 80%;
                }
            }
            .code{
                border: 3px solid #40A0FF;
                box-shadow: 0px 4px 12px 0px rgba(115,173,234,0.15);
                height: 54px;
                margin: 0px 20px 50px;
                background: #FFFFFF;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                .codeInp{
                    display: flex;
                    align-items: center;
                    width: 55%;
                    font-size: 16px;
                    font-weight: 500;
                    input{
                        width: 80%;
                    }
                    img{
                        width: 15px;
                        height: 18px;
                        margin-left: 12px;
                        margin-right: 11px;
                    }
                }
                
                button{
                    width: 84px;
                    height: 36px;
                    background: #F29129;
                    border-radius: 4px;
                    margin-right: 9px;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 12px;
                }
            }
            .downLoad{
                width: 240px;
                height: 53px;
                line-height: 53px;
                text-align: center;
                // background: linear-gradient(90deg, #0060FF 0%, #3284FF 100%);
                background: url('../../assets/images/staffInvite/btn_bg.png');
                background-repeat: no-repeat;
                background-size: 100%;
                border-radius: 24px;
                position: absolute;
                bottom: -24px;
                left: 50%;
                margin-left: -120px;
                font-weight: bold;
                color: #FFFFFF;
                font-size: 18px;
            }
        }
        .text{
            color: #E6FAFF;
            font-size: 12px;
            text-align: center;
            margin-top: 53px;
        }
        .rule{
            margin: 39px 20px 32px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .prompt{
            margin: 32px 20px 0;
            background: #2D3A50;
            border-radius: 13px;
            overflow: hidden;
            .prompt_title{
                text-align: center;
                margin-top: 25px;
                img{
                    height: 22px;
                }
            }
            .prompt_main{
                color: #8DC5D4;
                padding: 13px 15px 37px;
                font-size: 14px;
                p{
                    margin-top: 20px;
                    line-height: 20px;
                }
            }
        }
        .specs{
            // width: 120px;
            text-align: center;
            color: #7DBCCE;
            font-size: 14px;
            // position: fixed;
            // bottom: 27px;
            // left: 50%;
            // margin-left: -60px;
            padding: 34px 0 27px;
            background: #0060FF;
            // margin-bottom: 27px;
        }
    }
</style>