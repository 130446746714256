<!--
 * @Author: your name
 * @Date: 2022-04-06 11:26:47
 * @LastEditTime: 2022-06-22 17:05:09
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D

%E7%BD%AE
 * @FilePath: \newwifi\src\views\selectpack\index.vue
-->
<template>
  <div class="selectpack">
    <div class="top" :class="{ unicomTop: info.type != 1 && info.type != 6  }">
    </div>
    <div class="center">
      <div class="titlebox" :class="{ change: !ischange }">
        <smalltitle :info="info" :iccid="iccid" @setWifi="changewifi()"></smalltitle>
        <div class="infobox" v-if="ischange">
          <funboxitem v-for="(item, index) in infolist" :key="index" :hownum="infolist.length" :item="item"
            :textcolor="'#47525d'" :bottomtext="'#8a9ab5'">
            <div class="text-top"><span class="sapn" :class="{ isstatus: index == 3 ? true : false }"
                :style="{ color: item.texttop }">{{ item.money }}</span>{{ item.company }}</div>
          </funboxitem>
        </div>
      </div>
      <div class="mealbox">
        <div class="ispurchange">
          <div class="left">套餐类型</div>
        </div>
        <div class="box1" v-if="info.is_tmall == 1">
            <van-radio-group v-model="vantradio" @change="vantChange">
            <van-radio :name="item.id" v-for="item in list" :key="item.id" @click="radioclick(item)">
                <div class="hot" v-if="item.is_hot == 1"><img src="@/assets/images/selectpack/hot.png" alt=""></div>
                <div class="outBox" :class="{hadChoosePackage:item.id == vantradio,noChoosePackage: item.id != vantradio}">
                  <div class="PackageList" :class="{hadChoosePackage1:item.id == vantradio}">
                    <div class="packicon">
                        <div class="packNum">{{item.con_unit}}</div>
                        <div class="packName">{{item.short_name}}</div>
                    </div>
                    <div class="packTitle">{{item.show_name}}</div>
                    <!-- <div class="packTip">约￥{{ Math.floor(item.price / (item.validity_day / 30))}}/月</div> -->
                    <div class="packPrice">￥<span :style="info.tmall_package != 0?'text-decoration-line: line-through;':''">{{item.price}}</span> 应付</div>
                    <div class="preferential" v-if="info.tmall_package != 0">用户减免￥{{info.tmall_package}}</div>
                    <!-- v-if="info.tmall_package != 0" -->
                  </div>
                  <div class="packTip" v-if="item.con_unit == '20G'">小流量包不推荐</div>
                  <div class="packTip" v-else>约￥{{ Math.floor(item.price / (item.validity_day / 30))}}/月</div>
                </div>
            </van-radio>
          </van-radio-group>
        </div>
        <div class="box" v-else    >
          <van-radio-group v-model="vantradio" @change="vantChange">
            <van-radio :name="item.id" v-for="item in list" :key="item.id" @click="radioclick(item)">
              <img src="@/assets/images/selectpack/hot.png" alt="" class="hot" v-if="item.is_hot == 1">
              <div class="vanttitlebox" :class="{
                selectradio: item.id == vantradio && info.has_free_package== 1 && info.is_tmall == 0,
                 nohaspackage: item.id == vantradio && info.has_free_package == 0 && info.is_tmall == 0, 
                 haspackage: item.id != vantradio && info.has_free_package == 0 && info.is_tmall == 0
              }">
                <div class="vanttitle">
                  <div class="title-left">
                    <p class="pone" :class="{ selectpone: item.id == vantradio }">{{ item.short_name }}</p>
                    <p class="ptwo" :class="{ selectptwo: item.id == vantradio }">超值特惠</p>
                  </div>
                  <div class="title-right">
                    <p class="right-pone" :class="{ rightspone: item.id == vantradio }"><span class="span"
                        :class="{ rightspan: item.id == vantradio }">￥</span>{{ item.price }}</p>
                    <p class="right-ptwo" :class="{ srightptwo: item.id == vantradio }">约￥{{ Math.floor
                    
                        (item.price / (item.validity_day / 30))
                    }}/月</p>
                  </div>
                </div>
              </div>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="mealinfo" >
        <div class="mealinfo-title">
          <div class="met-left">套餐简述</div>
          <div class="met-right">
            <div class="box-right" v-for="(item, index) in list" :key="index"
              :class="{ 'met-sright': item.id == vantradio }"></div>
          </div>
        </div>
        <div class="info" v-for="(item, index) in mealList" :key="index">
          <div class="info-left">
            <img :src="item.url" alt="" class="img">
            <div class="text">{{ item.title }}</div>
          </div>
          <div class="info-right" :class="{ infoadj: item.id == 3 ? true : false }">{{ item.info }}</div>
        </div>
      </div>
      <div class="priceDetail" v-if="couponList.length != 0">
        <div class="priceDetail_title">价格明细</div>
        <div class="allPrice">
          <div class="allPriceTitle">
            套餐总价
            <span>月套餐</span>
          </div>
          <div class="allPriceMoney">￥<span>{{moneyitem.price}}</span></div>
        </div>
        <div class="couponPrice" @click="openPopFn">
          <div class="couponPrice_left">
            <img src="@/assets/images/selectpack/coupon_icon.png" alt="">
            <div>优惠券</div>
          </div>
          <!-- <div class="couponPrice_right">
            <div class="couponNum"><span>{{couponList.length?couponList.length:0}}</span>张可用</div>
            <div class="couponMoney">减￥<span>{{selectList[0]?selectList[0].value:0}}</span></div>
            <van-icon name="arrow" color="#CCCCCC"/>
          </div> -->
          <div class="couponPrice_right">
            <div class="hasUse" ><span>{{couponList.length?couponList.length:0}}张</span>{{selectList[0]?'已使用':'未使用'}}</div>
            <van-icon name="arrow" color="#CCCCCC"/>
          </div>
        </div>
        <div class="couponType2" @click="openPopFn" v-if="selectList.length">
          <div class="sanjiao"></div>
          <div class="">延长券</div>
          <div class="time">套餐有效期 <span> + {{selectList[0].gift_day}}天</span></div>
        </div>
        <div class="totalPrice">
          <div class="totalPrice_name">合计</div>
          <div class="totalPrice_money">￥<span>{{moneyitem.price}}</span></div>
        </div> 
      </div>
      <div style="height:74px" v-if="weixin"></div>
      <popup ref="pops" :showf="showpop" @closepop="closepopFn" :list="couponList" :selist="selectList" @nextPage="nextPage" :page="page" :moneyitem="moneyitem"
      @selectCoupsiItem="selectCoupsiItemFn"></popup>
      <paymode :weixin="weixin" @change="paychange($event)" v-if="!weixin"></paymode>
    </div>
    <div class="bottom1" v-if="info.is_tmall == 1">
      <dir class="bottom-box">
        <div class="bottom-left">
          <div class="right">
            <!-- <div class="money">合计： <span class="span">￥</span><span class="span1">{{ moneyitem.price - info.tmall_package >0?moneyitem.price - info.tmall_package:moneyitem.price}}</span></div> -->
            <div class="money">合计： <span class="span">￥</span><span class="span1">{{fullPrice}}</span></div>
            <div class="text" v-if="info.tmall_package != 0">已减￥{{info.tmall_package}}</div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="submit" v-if="prohibit">
            <van-loading color="#fff" size="16px" />支付中...
          </div>
          <div class="submit" v-else @click="clkbuy">立即订购</div>
        </div>
      </dir>
    </div>
    <div class="bottom" v-else>
      <dir class="bottom-box">
        <div class="bottom-left">
          <div class="left" @click="showqrcode = !showqrcode">
            <img src="@/assets/images/selectpack/service.png" alt="" class="img">
            <div class="blb-text">咨询</div>
          </div>
          <img src="@/assets/images/selectpack/line.png" alt="" class="img-top">
          <div class="right">
            <!-- 如果有优惠券  再判断套餐的价格 减去优惠券的价格 是否 大于 0  如果是 显示 计算后的金额  如果不是显示0   如果没有优惠券，显示套餐金额-->
            <div class="money"> <span class="span">￥</span>{{moneyitem.price}}</div>
            <div class="text">金额</div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="submit" v-if="prohibit">
            <van-loading color="#fff" size="16px" />支付中...
          </div>
          <div class="submit" v-else @click="clkbuy">订购套餐</div>
        </div>
      </dir>
    </div>

    <van-popup v-model="reshow" position="bottom" :style="popupHeight" round closeable>
      <div class="popup-top">
        <div class="pop-center">
          <div class="popup-title">
            ICCID:<span>{{ iccid }}</span>
          </div>
          <div class="popup-toast">
            <span class="money">{{ info.c_balance }}</span><span class="company">元</span>当前余额不足,请及时充值！
          </div>
        </div>
        <div class="center-box">
          <p class="center-text">充值金额</p>
          <div class="moneybox">
            <mealbox v-for="item in meallistbox" :key="item.id" :item="item" :active="active"
              @selectitem="selectitem($event)"></mealbox>
          </div>
          <div class="inmeal">{{ info.pay_status == 0 ? "未订购套餐" : info.cur_buy_name }}</div>
        </div>
      </div>
      <bluebutton :title="titletext" @clkbutton="clkbutton" :isloading="isloading"></bluebutton>
    </van-popup>
    <qrcode :showqrcode="showqrcode" @clkqrcode="clkqrcode">
      <img :src="url" alt="" class="qrcode-img">
    </qrcode>
    <div class="setvan">
      <van-overlay :show="show" @click.stop>
        <div class="re-box">
          <div class="text">
            <p class="h3">请您确认购买套餐</p>
            本次购买套餐流量将进行叠加，优先使用之前套餐流量，之前套餐有效期不变，本次套餐有效期止

            {{ moneyitem.validity_day }}天后！
          </div>
          <div class="button">
            <div class="button-right" @click="show = false">取消</div>
            <div class="button-left" @click="query">确定</div>
          </div>
        </div>
      </van-overlay>
    </div>
    <newswifi :show="newswifiShow" @clkOverlay="newswifiShow = !newswifiShow" @change="change" :cardinfo="info">
    </newswifi>
    <querytoastbox :show="queryShow" @clkleft="queryShow = false" @clkright="clkQuqeryRight">
      <template #center>
        <div class="querycenter">即将打开"锐wifi"小程序</div>
      </template>
    </querytoastbox>
    <querytoastbox :show="alipayshow" :bottomInfo="PaybottomInfo" @clkleft="clkAlipayLeft" @clkright="clkAlipayRight">
      <template #center>
        <div class="querycenter">是否支付成功</div>
      </template>
    </querytoastbox>
    <confirmbox :show="newcomshow" @closebtn="closebtn()" @determine="determine"></confirmbox>
    <querytoastbox :show="newbuttomshow" :isnewButtm="true" @clknew="clknew">
      <template #center>
        <div class="newquerycenter">多次打开微信支付可能会被限制，如果无法正常唤起请选择支付宝支付</div>
      </template>
    </querytoastbox>


    <!-- <div class="mask" v-if="isFlag">
        <div class="loading" >
          <van-loading size="24px" color="#fffff" vertical  text-color="#ffffff" >加载中...</van-loading>
        </div>

    </div> -->
  </div>
</template>
<script>
import popup from './components/popup.vue'
import isAli from "@/untils/isAli"
import confirmbox from "@/components/confirmbox/index.vue"
import querytoastbox from "@/components/querytoastbox/index.vue"
import isweixin from "@/untils/isweixin.js"
import paymode from "@/components/paymode/index.vue"
import smalltitle from "@/components/smalltitle/index.vue"
import funboxitem from "@/views/index/components/funboxitem.vue"
import paymixin from "@/mixin/paymixin.js"
import mealbox from "@/components/mealbox/index.vue"
import bluebutton from "@/components/bluebutton/index.vue"
import qrcode from "@/components/qrcode/index.vue"
import isPhone from "@/untils/isPhone.js"
import newswifi from "@/components/newswifi/index.vue"
import {getCouponsList} from '@/untils/api.js'
export default {
  data() {
    return {
      newbuttomshow: false, // 展示单个按钮的弹窗
      PaybottomInfo: {
        left: "取消支付",
        right: "支付成功"
      },  // 微信和支付宝支付跳转前的询问
      newcomshow: false,  // 实名认证弹窗提示
      bottomInfo: {
        left: "取消",
        right: "确认"
      },  // 询问对话框的底部文字
      alipayshow: false,  // 支付宝返回弹窗
      queryShow: false,  // 是否显示确认前往微信小程序
      weixin: false,  // 是否在微信环境下
      vantradio: 1,  // vant组件库单选框默认选中的item
      infolist: [
        {
          text: "账户余额",
          money: "",
          company: "元",
          texttop: "#47525d"
        },
        {
          text: "已用",
          money: "",
          company: "",
          isloading: true,
          color: "#1a6af1",
          texttop: "#47525d"
        },
        {
          text: "剩余天数",
          money: "",
          company: "天",
          texttop: "#47525d"
        },
        {
          text: "设备状态",
          money: "",
          company: "",
          texttop: "#47525d"
        }
      ],  // 详细信息区域列表
      radio: '1', // 默认选中的套餐
      info: {},  // 头部详细信息
      list: [],  // 正常套餐列表
      iccid: "",  // 套餐设备id
      moneyitem: {}, // 选中的套餐详情
      mealList: [
        {
          url: require("@/assets/images/selectpack/balance.png"),
          title: "套餐资费",
          info: "",
          id: 1
        },
        {
          url: require("@/assets/images/selectpack/flow.png"),
          title: "套内流量",
          info: "",
          id: 2
        },
        {
          url: require("@/assets/images/selectpack/calendar.png"),
          title: "有效期",
          info: "",
          id: 3
        }
      ],  // 套餐详情列表
      prohibit: false,  // 支付按钮被点击
      reshow: false,  // 余额不足的充值弹窗
      meallistbox: [
        {
          money: 80,
          id: 1,
        },
        {
          money: 100,
          id: 2,
        },
        {
          money: 120,
          id: 3,
        },
        {
          money: 150,
          id: 4,
        },
        {
          money: 180,
          id: 5,
        },
        {
          money: 200,
          id: 6,
        },
      ], // 充值金额数组
      titletext: "立即充值",
      active: 1,  // 默认选中的值
      activeItme: {
        money: 80,
        id: 1,
      },// 默认选中的充值钱数
      isloading: true,  // 是否显示加载Loading
      showqrcode: false, // 二维码弹出
      url: require("@/assets/images/guide/qrcode.jpg"),
      ischange: false,  // 是否展示头部的设备详细信息
      popupHeight: {},  // 根据手机系统判断弹出高度
      is_first: 1,  // 是不是第一次购买
      show: false,  // 确定购买弹窗
      newswifiShow: false,  // 新的wifi弹窗是否显示(用于切换wifi设备)
      payradio: "1",  // 支付方式 1是微信2是支付宝
      newoffcardinfo: {},  //官方的详细信息
      rightNum: 0,  // 点击允许跳转小程序按钮次数
      activeInfo: {}, //当前设备信息
      isFlag:false,
      couponPrice:0,//第一张优惠券的金额
      couponList:[],//优惠券列表
      showpop: false,//优惠券列表弹窗是否显示
      selectList: [],// 选择的优惠券
      limit:5,
      page:1,
      totalPage:0,
      phone:'',
    }
  },
  mixins: [paymixin],
  created(){
    if(this.$route.query.iccid){
      window.sessionStorage.setItem('iccid',this.$route.query.iccid)
    }
    this.getInfo()
  },
  mounted() {
    // this.getInfo()
    this.weixin = isweixin()
    // 根据机型判断高度
    this.setPHeight()
    // JS原生设置当前背景颜色
    document.querySelector('body').setAttribute('style', 'background-color:#f3f5f7')
    this.getList()
    this.iccid = sessionStorage.getItem("iccid")
    this.isFlag = true
    this.getoffinfo()

    
    //计算变量


    this.getUrlQcode()
    // 如果是支付宝环境
    if (isAli()) {
      this.payradio = "2"
    }
    if(!this.weixin){
      this.payradio = "2"
    }
  },
  methods: {
    nextPage(item){
      if(this.page == this.totalPage){
        this.$refs.pops.finished=true
      }else{
        this.page = item + 1
        this.getCouponsList()
      }
  },
    selectCoupsiItemFn(item) {
      // 有
      if (this.selectList.length != 0) {
        //是否是当前选择的 
        if (item.id == this.selectList[0].id) {
          this.selectList = []
          this.couponPrice = 0
        }else{
          if(item.amount_limit > this.moneyitem.price){
            this.selectList = []
            this.$toast('体验套餐不可用')
          }else{
            this.selectList = []
            this.selectList.push(item)
            this.couponPrice = item.value
          }
        }


      } else {
        if(item.amount_limit > this.moneyitem.price){
          this.selectList = []
          this.$toast('体验套餐不可用')
        }else{
          this.selectList.push(item)
          // this.couponPrice = item.value
          if(item.coupon_type == 1 && item.use_as_balance == 1){
            this.price = item.value
          }
        }
      }

      //如果有优惠券  点击  =》 取消 
      //如果没有选择优惠券  点击   加入

    },
    closepopFn() {
      this.showpop = false
    },
    // 打开优惠券列表
    openPopFn() {
      this.showpop = true
    },
    getCouponsList(){
      let data = {
        // phone:'15825858556',
        // phone:sessionStorage.getItem("phone"),
        phone:this.phone,
        state: 1,
        page:this.page,
        limit:this.limit,
        type:2,// 优惠劵页面传1   套餐订购页面传2  
      }
      getCouponsList({data}).then(res => {
        if(res.data.code == 1){
          this.totalPage = Math.ceil(res.data.list.total/this.limit)
          if(this.page == this.totalPage){
            this.$refs.pops.finished=true
          }
          if(res.data.list.data.length == 0){
            this.$refs.pops.finished=true
          }
          res.data.list.data.forEach(element => {
            element.start_time = element.start_time.substr(0,10)
            element.expire_time= element.expire_time.substr(0,10)
          });
        }
        this.couponList =[...this.couponList, ...res.data.list.data] 
        if (this.couponList.length != 0) {
          // this.selectList.push(this.couponList[0])
          for(let i = 0; i < this.couponList.length; i++){
            if(this.couponList[i].amount_limit <= this.moneyitem.price){
              this.selectList.push(this.couponList[i])
              return
            }
          }
        }
      })
    },
    changewifi(){
      if(this.isFlag == true){
        return this.$toast('数据加载中，请稍后再试')
      }
      this.newswifiShow = true
    },
    //计算已用的流量
    subMoney() {
      // console.log(123);
      this.infolist[1].money = Math.round(Math.ceil(this.activeInfo.total_flow - this.offcardinfo.surplus_flow) / 1024)>=0?Math.round(Math.ceil(this.activeInfo.total_flow - this.offcardinfo.surplus_flow) / 1024):0
      this.infolist[1].company = 'GB'
      this.isFlag = false
    },

    // 关闭允许次数过多，弹起来的微信支付被封提示
    clknew() {
      this.newbuttomshow = false
      let iccid = window.sessionStorage.getItem("iccid")
      let data = {
        iccid: iccid,
        type: 1
      }
      this.getUrl(data)
      setTimeout(() => {
        this.alipayshow = true
      }, 1000)
    },
    closebtn() {
      this.newcomshow = false
    },
    determine() {
      this.newcomshow = false
      // window.location.href="http://iot.yunruiot.com/certif_entry.html?userCode=r/s9Tc00hjiKcR13MIjZHg==&passWord=9u8VuY1xbez6r6k/BBnLlw==&phone="+this.info.phone+"&accessNbr="+this.info.new_iccid
      window.location.href = this.info.real_url
    }, // 是否点击了确定实名认证
    // 支付宝回来的弹窗点击了重新支付按钮
    clkAlipayLeft() {
      this.alipayshow = false
    },
    // 支付宝回来后点击了确定按钮
    clkAlipayRight() {
      if (this.info.cur_buy_id > 0 && JSON.stringify(this.newoffcardinfo) != "{}" && this.newoffcardinfo.ispick

        == 1) {
        this.alipayshow = false
        this.newcomshow = true
      } else {
        this.$router.push("/index")
      }
    },
    clkQuqeryRight() {
      // 点击了确定前往微信小程序
      this.rightNum++
      if (this.rightNum < 2) {
        setTimeout(() => {
          this.alipayshow = true
        }, 1000)
      }
      this.jumpApplet()
    },
    // 支付方式发生了改变
    paychange($event) {
      this.payradio = $event
    },
    // vant单选框组件
    vantChange(name) {
      this.vantradio = name
    },
    // vant的radio被点击了
    radioclick(item) {
      this.moneyitem = item
      for(let i = 0; i < this.couponList.length; i++){
        if(this.couponList[i].amount_limit <= this.moneyitem.price){
          this.selectList.push(this.couponList[i])
        }else{
           this.selectList = []
        }
      }
      this.mealList[0].info = item.show_name
      this.mealList[1].info = item.con_unit
      this.mealList[2].info = "套餐有效期" + item.validity_day + "天"
    },
    // 监听切换wifi设备
    change() {
      // if(this.isFlag == false){
      //   return 
      // }
      // console.log(44444);
      // if(this.isFlag == false){
      //   return false
      // }
      this.isFlag = true
      this.newswifiShow = false
      this.iccid = sessionStorage.getItem("iccid")
      this.infolist[1].money = ""
      this.infolist[1].company = ""
      this.infolist[1].isloading = true
      this.getList()
      this.getInfo()
      
      this.getoffinfo()
      // console.log(this.info);
   

    },
    // 点击购买套餐
    clkbuy() {
      this.info.pay_status == 1 ? this.show = true : this.pay()
    },
    // 根据机型判断高度
    setPHeight() {
      if (isPhone() && document.documentElement.clientHeight <= 667) {
        this.popupHeight = {
          height: "70%"
        }
      } else {
        this.popupHeight = {
          height: "60%"
        }
      }
    },
    // 关闭弹出层
    clkqrcode() {
      this.showqrcode = false
    },
    // 获取企业二维码
    getUrlQcode() {
      this.getWechatQRcode().then(res => {
        this.url = res.data.info.wechat_img
      })
    },
    getInfo() {
      let data = {
        iccid: sessionStorage.getItem("iccid") ? sessionStorage.getItem("iccid") : this.iccid
      }
      this.getindexinfo({ data }).then(res => {
        // console.log(res,'res');
        window.sessionStorage.setItem('phone',res.data.info.phone)
        this.phone = res.data.info.phone
        this.getCouponsList()
           if(res.data.info.buy_id == 0 ){
            this.ischange = false
      }
        this.activeInfo = res.data.info
      })
    },

    // 获取官方信息
    getoffinfo() {
      let data = {
        iccid: sessionStorage.getItem("iccid") ? sessionStorage.getItem("iccid") : this.iccid
      }
      this.officialState({ data }).then(res => {
        if (res.data.code == 1) {
          this.offcardinfo = res.data.info
         
          this.newoffcardinfo = res.data.info
          this.infolist[1].money = res.data.info.used
          this.infolist[1].company = res.data.info.used_unit
          this.infolist[1].isloading = false
          // console.log(res);
          this.subMoney()
        } else {
          this.$toast(`${res.data.msg}`)
        }
      })
    },
    // 选中的actice
    selectitem($event) {
      this.active = $event.id
      this.activeItme = $event
    },
    // 跳往微信小程序
    jumpApplet() {
      if ((!this.weixin) && this.payradio == 1) {
        if (this.rightNum < 2) {
          let iccid = window.sessionStorage.getItem("iccid")
          let data = {
            iccid: iccid,
            type: 1
          }
          this.getUrl(data)
        }
        if (this.rightNum >= 2) {
          this.alipayshow = false
          this.queryShow = false
          this.newbuttomshow = true
        }
      }
      return false
    },
    // 立即充值按钮
    clkbutton() {
      if ((!this.weixin) && this.payradio == 1) {
        this.queryShow = true
        return false
      }
      this.isloading = false
      let data = {
        price: this.activeItme.money, // 金额
        iccid: this.iccid,  // 套餐设备id
        phone: sessionStorage.getItem("phone"),  //手机号
        type: this.ischange ? 2 : 1,  //购买类型
        buy_id: this.moneyitem.id,  // 购买id
        platform: 1, // 1是h5
        openid: sessionStorage.getItem("openid"),  // 微信openid
        pay_type: this.payradio == 1 ? "1" : "5",  // 支付状态1是微信5是支付宝
        coupon_id:this.selectList[0]?this.selectList[0].id:0,//优惠券
      }
      this.setpayinfo(data)
    },
    // 关闭弹出层
    close() {
      this.reshow = false
    },
    query() {
      this.show = false
      this.pay()
    },
    // 支付按钮
    pay() {
      // 编辑中
      if ((!this.weixin) && this.payradio == 1) {
        this.queryShow = true
        return false
      }
      this.prohibit = true
      let data = {
        type: this.ischange ? 2 : 1,  //购买类型
        buy_id: this.moneyitem.id,
        phone: sessionStorage.getItem("phone"),  //手机号
        price: this.moneyitem.price, // 金额
        iccid: this.iccid,  // 套餐设备id
        coupon_id:this.selectList[0]?this.selectList[0].id:0,//优惠券
      }
      let Paydata = {
        price:  this.moneyitem.price, // 金额
        iccid: this.iccid,  // 套餐设备id
        phone: sessionStorage.getItem("phone"),  //手机号
        type: this.ischange ? 2 : 1,  //购买类型
        buy_id: this.moneyitem.id,  // 购买id
        platform: 1, // 1是h5
        openid: sessionStorage.getItem("openid"),  // 微信openid
        pay_type: this.payradio == 1 ? 1 : 5, //1是微信 2是余额
        has_free_package: 0,
        coupon_id:this.selectList[0]?this.selectList[0].id:0,//优惠券
      }
      this.checkBalance({ data }).then(res => {
        if (this.moneyitem.is_default == 1 && res.data.code == 0) {
          this.prohibit = false
          this.$toast("余额不足,请充值")
          // 为了适应小流量套餐 套餐充值金额首个可选项不固定
          if (this.moneyitem.price <= 50) {
            this.meallistbox.shift()
            this.meallistbox.unshift({
              money: 50,
              id: 1
            })
            if (this.activeItme.id == 1) {
              // 如果用户没有进行充值金额改变，则需要改变初始默认值
              // 改变初始选中的钱数
              this.activeItme.money = 50
            }
          }else if(this.moneyitem.price==89){
            if(this.meallistbox[0].money == 80){
            this.meallistbox.shift()

            this.meallistbox.shift()
            this.meallistbox.unshift({
              money: 100,
              id: 1
            })
            if (this.activeItme.id == 1) {
              // 如果用户没有进行充值金额改变，则需要改变初始默认值
              // 改变初始选中的钱数
              this.activeItme.money = 100
            }

            }
         
        
          } else {
            this.meallistbox.shift()
            this.meallistbox.unshift({
              money: 80,
              id: 1
            })
            if (this.activeItme.id == 1) {
              // 如果用户没有进行充值金额改变，则需要改变初始默认值
              // 改变初始选中的钱数
              this.activeItme.money = 80
            }
          }
          this.reshow = true
          return false
        } else if (res.data.code == 0 && this.moneyitem.is_default == 0) {
          this.$toast("余额不足,你还需要支付" + res.data.price + "元")
          this.setpayinfo(Paydata)
        } else if (res.data.code == 1) {
          this.$toast("余额充足,你将使用余额支付")
          this.setpayinfo(Paydata)
        }
      })
    },
    deepCopy(obj) {
      // 只拷贝对象
      if (typeof obj !== 'object') return;
      // 根据obj的类型判断是新建一个数组还是一个对象
      var newObj = obj instanceof Array ? [] : {};
      for (var key in obj) {
        // 遍历obj,并且判断是obj的属性才拷贝
        if (obj.hasOwnProperty(key)) {
          // 判断属性值的类型，如果是对象递归调用深拷贝
          newObj[key] = typeof obj[key] === 'object' ? this.deepCopy(obj[key]) : obj[key];
        }
      }
      return newObj;
    },
    // 获取套餐列表
    getList() {
      let data = {
        iccid: sessionStorage.getItem("iccid"),
        type: 1
      }
      this.getPackageList({ data }).then(res => {
        if (res.data.code == 1) {
          res.data.list.forEach(item => {
            if((item.price - 0)%1 === 0 ){
              item.price = Math.trunc(item.price - 0)
            }
          });
          this.list = res.data.list
          this.info = res.data.info
          if (this.info.iccid_status != "正常") {
            this.infolist[3].texttop = "#ff0000"
          }
          // 如果是变更/续费套餐套餐的话
          if (res.data.info.cur_buy_id > 0) {
            this.ischange = true
            this.is_first = 0
            this.infolist[0].money = res.data.info.c_balance
            this.infolist[2].money = res.data.info.exp_days
            this.infolist[3].money = res.data.info.iccid_status
          }
          this.vantradio = this.list[0].id
          this.moneyitem = this.list[0]
          // 进行初始数值设置
          this.mealList[0].info = this.moneyitem.show_name
          this.mealList[1].info = this.moneyitem.con_unit
          this.mealList[2].info = "套餐有效期" + this.moneyitem.validity_day + "天"
        } else {
          this.$toast(`${res.data.msg}`)
        }
      })
    }
  },
  components: {
    smalltitle,
    funboxitem,
    mealbox,
    bluebutton,
    qrcode,
    newswifi,
    paymode,
    querytoastbox,
    confirmbox,
    popup,
  },
  computed:{
    fullPrice:function(){
        if(this.moneyitem.price - this.info.tmall_package >= 0){
            return this.moneyitem.price - this.info.tmall_package
        }else{
            return this.moneyitem.price
        }
    }
  }
}
</script>
<style lang="less" scoped>

.selectpack {
  padding-bottom: 60px;
  

.mask {
  position: fixed;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: rgba(34, 34, 34, 0.61);
    border-radius: 10px;
  }
}
  .top {
    width: 100%;
    background-image: url("~@/assets/images/selectpack/newbg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 190px;
  }

  .unicomTop {
    background-image: url("~@/assets/images/selectpack/unicomBg.png") !important;
  }

  .center {
    width: 92%;
    margin: -32px auto 0;

    .change {
      height: 48px;
      display: flex;
      justify-items: center;
      align-items: center;

      /deep/.smalltitle {
        padding-top: 0;
      }
    }

    .titlebox {
      background-color: #f8fafb;
      border-radius: 10px;

      .infobox {
        display: flex;
        height: 42px;
        margin-top: 20px;
        padding-bottom: 20px;

        /deep/.isloading {
          color: #8a9ab5;
        }

        .text-top {
          font-size: 12px;
          color: #47525d;

          .isstatus {
            font-size: 14px !important;
            line-height: 20px;
          }

          .sapn {
            font-size: 20px;
            color: #47525d;
            font-weight: 600;
            transform: scale(0.75);
          }
        }
      }
    }

    .mealbox {
      width: 100%;
      margin-top: 18px;

      .ispurchange {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        margin-bottom: 28px;

        .left {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
        }

        .right {
          font-size: 12px;
          color: #999999;
        }
      }

      .item-box {
        width: 100%;
        min-height: 46px;
        margin-bottom: 44px;

        .img {
          width: 100%;
          display: block;
        }
      }
      .box1{
        width: 100%;
        margin-bottom: 16px;
        /deep/.van-radio-group {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-flow: row wrap;
            justify-content: flex-start;
            .van-radio{
                // margin-bottom: 12px;
                flex: 0 0 33.3%;
                position: relative;
                
            }
            .van-radio__icon{
                display: none;
            }
            .van-radio__label{
                margin-left: 0;
                line-height: none;
                position: relative;
                .hot {
                      // width: 33px;
                      // height: 18px;
                      position: absolute;
                      right: 20px;
                      top: 20px;
                      img{
                        width: 38px;
                        height: 21px;
                      }
                }
                .outBox{
                  // height: 200px;
                  // padding-bottom: 20px;
                  width: 108px;
                  background: #E0E7EE;
                  border-radius: 10px;
                  margin-top: 12px;
                  box-sizing: border-box;
                  .PackageList{
                    width: 100%;
                    // height: 180px;
                    // background-size: 100% auto !important;
                    text-align: center;
                    overflow: hidden;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: center;
                    border-radius: 10px;
                    background: #fff;
                    box-sizing: border-box;
                    .packicon{
                        width: 69px;
                        height: 55px;
                        background: url("~@/assets/images/TmallSelectpack/Pack_icon.png");
                        background-size: 100% auto;
                        overflow: hidden;
                        margin: 18px auto 5px;
                        .packNum{
                            font-size: 12px;
                            font-weight: bold;
                            color: #FBFFC9;
                            margin: 11px auto 0 auto;
                            text-align: center;
                        }
                        .packName{
                            font-weight: bold;
                            font-size: 10px;
                            color: #FFFFFF;
                            text-align: center;
                        }
                    }
                    .packTitle{
                        color: #254674;
                        font-weight: bold;
                        font-size: 12px;
                        margin-top: 7px;
                        text-align: center;
                        width: 85%;
                        margin:  0 auto;
                        // white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;

                    }
                    
                    .packPrice{
                        color: #46638D;
                        font-size: 11px;
                        margin: 10px 0;
                        // margin-bottom: 10px;
                        span{
                            font-size: 18px;
                            color: #46638D;
                            font-weight: bold;
                            font-family: "bahn";

                        }
                    }
                    .preferential{
                        font-size: 11px;
                        color: #FE7000;
                        margin-bottom: 15px;
                    }
                  }
                  .packTip{
                        // color: #46638D;
                        color: #758090;
                        font-size: 11px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        // margin: 5px 0;
                    }
                  .hadChoosePackage1{
                    background: url("~@/assets/images/TmallSelectpack/hadPack_bg.png");
                    background-size: 100% 100%;
                  }  
                }
                
                .hadChoosePackage{
                  // background: url("~@/assets/images/TmallSelectpack/hadPack_bg.png");
                  background-color: #005FEB;
                  border-radius: 10px;
                  .packTip{
                    color: #fff;
                  }
                }
                .noChoosePackage{
                  // border: 2px solid;
                  // border-image: linear-gradient(0deg, #015EEA, #00C0FA) 10 10;
                  // border-radius: 10px;
                    // background: url("~@/assets/images/TmallSelectpack/Pack_bg.png");
                }
            }
        }
      }
      .box {
        
        width: 100%;
        margin-bottom: 16px;

        /deep/.van-radio-group {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .van-radio {
            margin-bottom: 12px;
          }

          .van-radio__icon {
            display: none;
          }

          .van-radio__label {
            width: 162px;
            margin-left: 0;
            line-height: none;
            position: relative;

            .hot {
              width: 33px;
              height: 18px;
              display: block;
              position: relative;
              left: 13px;
              top: 9px;
            }

            .selectradio {
              background-image: url("~@/assets/images/selectpack/select.png") !important;
            }

            .haspackage {
              width: 100%;
              height: 62px !important;
              background-image: url("~@/assets/images/selectpack/newdefalut.png") !important;
            }

            .nohaspackage {
              width: 100%;
              height: 62px !important;
              background-image: url("~@/assets/images/selectpack/newselect.png") !important;
            }

            .vanttitlebox {
              width: 100%;
              height: 100px;
              background-image: url("~@/assets/images/selectpack/defalut.png");
              background-repeat: no-repeat;
              background-size: contain;
              box-sizing: border-box;
              padding: 0 13px;
              display: flex;
              justify-content: space-between;
              transition: height 0.5s;

              .vanttitle {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 14px;

                .title-left {
                  font-weight: 600;

                  .selectpone {
                    color: #fff !important;
                  }

                  .pone {
                    font-size: 14px;
                    color: #50626e;
                  }

                  .selectptwo {
                    color: #ffeda6 !important;
                  }

                  .ptwo {
                    font-size: 12px;
                    color: #767793;
                    margin-top: 4px;
                  }
                }

                .title-right {
                  .rightspone {
                    color: #fff !important;
                  }

                  .right-pone {
                    font-size: 22px;
                    color: #50626e;
                    font-family: "bahn";
                    text-align: right;

                    .rightspan {
                      color: #fff !important;
                    }

                    .span {
                      color: #50626e;
                      font-size: 12px;
                      font-weight: 600;
                    }
                  }

                  .srightptwo {
                    color: #a6d6fb !important;
                  }

                  .right-ptwo {
                    font-size: 12px;
                    color: #ff2700;
                    text-align: right;
                  }
                }
              }
            }
          }
        }

      }
    }

    .isweixininfo {
      padding-bottom: 74px;
    }

    .mealinfo {
      width: 100%;

      .mealinfo-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        margin-bottom: 24px;

        .met-left {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
        }

        .met-right {
          display: flex;

          .box-right {
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: #cdd1d7;
            margin: 0 4px;
          }

          .met-sright {
            background-color: #4888f3;
          }
        }
      }

      .info {
        display: flex;
        margin-bottom: 30px;

        .info-left {
          display: flex;
          justify-items: center;
          align-items: center;
          margin-right: 30px;

          .img {
            width: 16px;
            display: block;
            margin-right: 6px;
          }

          .text {
            color: #999999;
            font-size: 14px;
          }
        }

        .info-right {
          color: #444444;
          font-size: 14px;
          font-weight: 600;
        }

        .infoadj {
          margin-left: 15px;
        }
      }
    }
  }
  .priceDetail{
    margin:15px 0px;
    padding: 15px 10px;
    background: #fff;
    border-radius: 8px;
    .priceDetail_title{
      color: #222222;
      font-weight: 500;
      font-size: 16px;
    }
    .allPrice{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
      .allPriceTitle{
        color: #666666;
        font-size: 13px;
        span{
          color: #CCCCCC;
          font-size: 12px;
        }
      }
      .allPriceMoney{
        color: #222222;
        font-weight: bold;
        font-size: 12px;
        font-family:'bahn' ;
        span{
          font-size: 18px;
        }
      }
    }
    .couponPrice{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .couponPrice_left{
        display: flex;
        align-items: center;
        img{
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
        div{
          color: #222222;
          font-size: 13px;
        }
      }
      .couponPrice_right{
        display: flex;
        align-items: center;
        margin: 27px 0;
        .hasUse{
          color: #FE4C56;
          margin-right: 5px;
          span{
            color: #FFFFFF;
            background: #FE4C56;
            padding: 2px 3px;
            border-radius: 3px;
            margin-right: 4px;
          }
        }
      }
    }
    .couponType2{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #FFF6E8;
      padding: 19px 10px;
      border-radius: 8px;
      margin-bottom: 14px;
      position: relative;
      font-size: 14px;
      color: #222222;
      .sanjiao{
        width:0px;
        height:0px;
        border:10px solid transparent;/*以下四个样式对应四种三角形，任选其一即可实现*/
        /* border-top-color:lightseagreen; */
        /* border-left-color:lightseagreen; */
        /* border-right-color:lightseagreen; */
        border-bottom-color:#FFF6E8;
        position: absolute;
        top: -20px;
        right: 20px;
      }
      .time{
        font-weight: bold;
        span{
          color: #FE4C56;
        }
      }
      
    }
    .totalPrice{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .totalPrice_name{
        color: #222222;
        font-size: 13px;
      }
      .totalPrice_money{
        font-size: 12px;
        font-weight: bold;
        font-family: 'bahn';
        span{
          font-size: 18px;
        }
      }
    }
  }
  .bottom1{
    width: 100%;
    height: 58px;
    position: fixed;
    right: 0;
    bottom: 50px;
    background-color: #fff;
    display: flex;
    justify-items: center;
    align-items: center;
	z-index: 3;
    .bottom-box{
        display: flex;
        width: 92%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bottom-left{
            .right{
                .money{
                    font-size: 12px;
                    font-weight: bold;
                    color: #222222;
                    .span{
                        color: #FF0000;
                    }
                    .span1{
                        font-size: 18px;
                        color: #FF0000;
                        font-weight: 550;
                    }
                }
                .text{
                    font-size: 10px;
                    color: #FF0000;
                    margin-top: 5px;
                }
            }
        }
        .bottom-right {
        .submit {
          background-color: #4888f3;
          border-radius: 10px 16px 4px 4px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          min-width: 120px;
          height: 48px;
          margin: 0 10px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;

          /deep/.van-loading {
            margin-right: 4px;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 60px;
    position: fixed;
    right: 0;
    bottom: 0 !important;
    background-color: #fff;
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 3;

    .bottom-box {
      display: flex;
      width: 92%;
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;

      .bottom-left {
        display: flex;

        .left {
          display: flex;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;

          .img {
            height: 16px;
            width: 16px;
            display: block;
            margin-bottom: 4px;
          }

          .blb-text {
            font-size: 12px;
            color: #aaaaaa;
            transform: scale(0.8);
            min-width: 24px;
          }
        }

        .img-top {
          height: 30px;
          display: block;
          margin: 0px 24px 0 20px;
        }

        .right {
          display: flex;
          flex-direction: column;

          .money {
            color: #ff0000;
            font-size: 18px;
            display: flex;
            justify-items: center;
            align-items: center;

            .span {
              color: #ff0000;
              font-size: 12px;
            }
          }

          .text {
            font-size: 12px;
            color: #aaaaaa;
            transform: scale(0.8);
            margin-top: 2px;
          }
        }
      }

      .bottom-right {
        .submit {
          background-color: #4888f3;
          border-radius: 10px 16px 4px 4px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          min-width: 120px;
          height: 48px;
          margin: 0 10px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;

          /deep/.van-loading {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .popup-top {
    width: 100%;
    background-color: #f6f8fc;
    height: 86px;

    .pop-center {
      width: 92%;
      margin: 0 auto;
      height: 86px;

      .popup-title {
        padding-top: 15px;
        color: #333333;
        font-size: 14px;
        font-weight: 600;

        .span {
          font-family: "bahn";
        }
      }

      .popup-toast {
        font-size: 12px;
        color: #b6c2d6;
        margin-top: 14px;

        .money {
          font-size: 26px;
          color: #f11a52;
          font-family: "bahn";
        }

        .company {
          color: #f11a52;
          margin-right: 12px;
        }
      }
    }

    .center-box {
      width: 92%;
      margin: 0 auto;

      .center-text {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        margin: 20px 0;
      }

      .moneybox {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
      }

      .inmeal {
        color: #dce5f5;
        margin-bottom: 16px;
      }
    }
  }

  .qrcode-img {
    width: 100%;
    display: block;
  }

  .setvan {
    /deep/.van-overlay {
      z-index: 6;

      .re-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        border-radius: 10px;

        .text {
          font-size: 14px;
          height: 90;
          border-bottom: 1px solid #7f7f7f;
          box-sizing: border-box;
          text-align: left;
          padding: 10px 20px;
          line-height: 1.5;
          height: 150px;

          .h3 {
            text-align: center;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .button {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 600;

          .button-left {
            width: 50%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-left: 1px solid #7f7f7f;
          }

          .button-right {
            width: 50%;
            height: 40px;
            text-align: center;
            line-height: 40px;
          }
        }

        .experience {
          box-sizing: border-box;
          padding: 10px;
          width: 95%;
          margin: 20px auto 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }

  .querycenter {
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    font-weight: 600;
  }

  .newquerycenter {
    text-align: center;
    height: 80px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    line-height: 1.3;
    align-items: center;
    justify-items: center;
  }
}
</style>